<template>
    <div class="container compare">
        <loading-spinner
            v-if="loading"
            class="fixed"
        />
        <b-row
            class="mt-40"
            :class="{ transparent: loading }"
        >
            <b-col cols="12">
                <h4>{{ $t('page.compare.heading') }}</h4>
            </b-col>
        </b-row>

        <div
            v-if="canLoad"
            class="compare-row np"
            :class="colClass"
        >
            <div class="info">
                <div class="titles" />
                <div
                    v-for="product in comparedProducts.products"
                    :key="`col-img-${product.code}`"
                    class="col"
                    :class="colClass"
                >
                    <b-button
                        v-if="comparedProducts.products.length > 2"
                        variant="text"
                        class="icon fill sm remove"
                        @click="remove(product.code)"
                    >
                        <x-icon />
                    </b-button>
                    <router-link :to="{ name: 'Produs', params: { slug: product.slug } }">
                        <div class="image-container">
                            <div class="image">
                                <img
                                    :src="product.thumbnail"
                                    :alt="product.name"
                                >
                            </div>
                        </div>
                    </router-link>

                    <router-link :to="{ name: 'Produs', params: { slug: product.slug } }">
                        <p class="sm-sbold mt-3 name">
                            {{ product.name }}
                        </p>
                    </router-link>

                    <rating-stars
                        :rating="{
                            value: product.rating,
                            counter: product.rating_counter,
                        }"
                        location="product"
                    />
                    <div class="prices">
                        <span
                            class="price"
                        ><del
                             v-if="product.discount_percentage"
                             v-dompurify-html="formatPrice(product.price)"
                         />
                            <p
                                v-if="!product.discount_percentage"
                                v-dompurify-html="formatPrice(product.current_price)"
                                class="has-sup sbold"
                            />
                            <p
                                v-else
                                v-dompurify-html="formatPrice(product.current_price)"
                                class="has-sup sbold"
                            />
                        </span>
                    </div>
                    <div class="actions d-flex mt-2">
                        <add-to-cart
                            :stock="product.stock_furnizor"
                            :product="cartProduct(product)"
                            type="icon fill"
                            :label="false"
                        />
                        <add-to-wishlist
                            :product="product"
                            location="listing"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="canLoad"
            class="compare-row mt-4"
            :class="colClass"
        >
            <div class="info heading">
                <div class="titles">
                    <p class="sbold">
                        {{ $t('page.compare.manufacturerLabel') }}
                    </p>
                </div>
                <div
                    v-for="product in comparedProducts.products"
                    :key="`col-brand-${product.code}`"
                    class="col"
                    :class="colClass"
                >
                    <p>
                        {{ product.manufacturer }}
                    </p>
                </div>
            </div>
        </div>
        <div
            v-if="canLoad"
            class="compare-row"
            :class="colClass"
        >
            <div class="info">
                <div class="titles">
                    <p class="sbold">
                        {{ $t('page.compare.stockLabel') }}
                    </p>
                </div>
                <div
                    v-for="product in comparedProducts.products"
                    :key="`col-stoc-${product.code}`"
                    class="col"
                >
                    <p>
                        {{
                            product.stock_furnizor
                                ? $t('page.product.stock.exist')
                                : $t('page.product.stock.unavailable')
                        }}
                    </p>
                </div>
            </div>
        </div>
        <div
            v-if="canLoad && existAttributes"
            :class="colClass"
            class="compare-row"
        >
            <div class="info heading">
                <div class="titles">
                    <p class="sbold">
                        {{ $t('page.compare.characteristicsLabel') }}
                    </p>
                </div>
                <div
                    v-for="product in comparedProducts.products"
                    :key="`col-ch-${product.code}`"
                    class="col mh"
                    :class="colClass"
                />
            </div>
        </div>
        <div
            v-if="canLoad"
            class="compare-row"
            :class="colClass"
        >
            <div
                v-for="attributeCategory in comparedProducts.attributeKeys"
                :key="`col-attrCat-${attributeCategory}`"
                class="attributes"
            >
                <div class="info">
                    <div class="titles">
                        <p class="sm-sbold">
                            {{ attributeCategory }}
                        </p>
                    </div>
                    <div
                        v-for="product in comparedProducts.products"
                        :key="`col-attr-${product.code}`"
                        class="col"
                    >
                        <p>
                            {{
                                product.attributes[attributeCategory]
                                    ? product.attributes[attributeCategory]
                                    : '-'
                            }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="canLoad && !emptySpecs"
            class="compare-row"
            :class="colClass"
        >
            <div class="info gray">
                <div class="titles">
                    <p class="sbold">
                        {{ $t('page.compare.specificationLabel') }}
                    </p>
                </div>
                <div
                    v-for="product in comparedProducts.products"
                    :key="`col-specs-${product.code}`"
                    class="col"
                >
                    <ul>
                        <li
                            v-for="characteristic in product.characteristics"
                            :key="`col-chars-${characteristic.name}`"
                        >
                            <p>{{ characteristic.name }} : {{ characteristic.value }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import XIcon from 'vue-feather-icons/icons/XIcon';
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapMutations, mapState } from 'vuex';

    import AddToCart from '@/components/controls/product/AddToCart';
    import AddToWishlist from '@/components/controls/product/AddToWishlist';
    import RatingStars from '@/components/product/RatingStars';
    import { splitedPrice } from '@/services/getSplitedPrice';

    import LoadingSpinner from '../components/loaders/LoadingSpinner';
    export default {
        name: 'Compare',
        components: {
            AddToCart,
            RatingStars,
            XIcon,
            AddToWishlist,
            LoadingSpinner,
        },
        data() {
            return {
                loading: false,
            };
        },
        computed: {
            ...mapState('compare', ['comparedProducts', 'toCompareData']),
            lang() {
                return i18n.locale;
            },
            colClass() {
                let col = this.loading ? 'transparent ' : '';
                switch (this.comparedProducts.products.length) {
                case 2:
                    col += 'w300';
                    break;
                case 3:
                    col += 'w200';
                    break;
                default:
                    col += 'w150';
                    break;
                }
                return col;
            },
            canLoad() {
                return Object.keys(this.comparedProducts.products).length > 0;
            },
            emptySpecs(){
                return this.comparedProducts.products.every(product => product.characteristics === null);
            },
            existAttributes(){
                return this.comparedProducts.attributeKeys.length > 0;
            }
        },
        methods: {
            ...mapActions('compare', ['getProducts']),
            ...mapMutations('compare', ['removeFromCompareData', 'resetCompareData']),
            async remove(productCode) {
                this.removeFromCompareData(productCode);
                await this.refreshCompareParams();
                this.initialize();
            },
            async initialize() {
                let params = this.$router.currentRoute.params;
                if (Object.prototype.hasOwnProperty.call(params, 'code')) {
                    this.loading = true;
                    await this.getProducts({ product_codes: params.code });
                    if (this.comparedProducts.products.length == 0) {
                        this.resetCompareData();
                        this.$router.go(-1);
                    }
                    await this.prepareCharacteristics();
                    this.loading = false;
                }
            },
            async prepareCharacteristics() {
                this.comparedProducts.products.forEach((element, index) => {
                    this.comparedProducts.products[index].characteristics =
                        element.characteristics;
                });
            },
            refreshCompareParams() {
                let compareCategory = Object.keys(this.toCompareData)[0];
                let codes = window.btoa(Object.keys(this.toCompareData[compareCategory]));
                this.$router.replace({ name: 'Comparare', params: { code: codes } });
            },
            formatPrice(price) {
                return splitedPrice(price);
            },
            cartProduct(product) {
                let cartProduct = {
                    id: product.id,
                    thumbnail: product.thumbnail,
                    name: product.name,
                    price: product.price,
                    discount_percentage: product.discount_percentage,
                    current_price: product.current_price,
                };
                return cartProduct;
            },
        },
        created() {
            this.initialize();
        },
        watch: {
            lang: {
                handler() {
                    this.initialize();
                },
                deep: true,
            },
        },
        metaInfo() {
            return {
                title: this.$t('meta.compare.title'),
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.$t('meta.compare.description'),
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: this.$t('meta.compare.keywords'),
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: this.$t('meta.compare.title'),
                        template: (chunk) => `${chunk} | Pescario.ro`,
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: window.location.origin + this.$route.fullPath,
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: this.$t('meta.compare.description'),
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: 'https://pescario.ro/logo.webp',
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: window.location.origin + this.$route.fullPath,
                    },
                ],
            };
        },
    };
</script>
<style scoped lang="scss">
.compare {
  @include media-breakpoint-down(md) {
    overflow: auto;
    .mt-40 {
      &:first-of-type {
        margin-top: 0;
      }
    }
  }

  .compare-row {
    width: 100%;
    .info {
      width: 100%;
      display: inline-flex;
      &.heading {
        width: 100%;
        > div {
          background-color: $gray-thin;
        }
      }
      &.gray {
        .titles,
        .col {
          background-color: $gray-thin;
        }
      }
      .titles {
        padding: 8px;
        @include media-breakpoint-down(lg) {
          width: 20%;
          p {
            font-size: 13px;
          }
        }
      }
      .col {
        padding: 8px 16px;
        flex: 1;
        position: relative;
        p {
          font-size: 14px;
          &.name {
            height: 48px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        @include media-breakpoint-down(lg) {
          padding: 8px 6px;
          flex: none;
          p {
            font-size: 12px;
            &.name {
              height: 44px;
            }
          }
        }
        @include media-breakpoint-down(md) {
          flex: none;
          width: 120px;
        }
        &.mh {
          min-height: 40px;
          @include media-breakpoint-down(xm) {
            min-height: 37px;
          }
        }
        .image-container {
          overflow: hidden;
          background-color: $gray-thin;
          padding: 10px;
          display: flex;
          justify-content: center;
          border-radius: 8px;
          .image {
            img {
              object-fit: contain;
              @include media-breakpoint-between(md, xxl) {
                height: 120px;
              }
              @include media-breakpoint-down(md) {
                height: 100px;
              }
            }
          }
        }
        .remove {
          position: absolute;
          right: 0px;
          margin: 0;
          padding: 0px 4px;
          height: 24px;
          svg {
            height: 16px;
            width: 16px;
          }
          @include media-breakpoint-down(lg) {
            height: 16px;
            padding: 0px 2px;
            svg {
              height: 12px;
              width: 12px;
            }
          }
        }
        .prices {
          .price {
            p {
              color: $primary;
            }
          }
        }
        .actions {
          .btn {
            margin: 0;
          }
        }
      }
    }
    &.w150 {
      @include media-breakpoint-between(sm, md) {
        width: 750px;
      }
      @include media-breakpoint-down(sm) {
        width: 600px;
      }
      .titles {
        width: 20%;
      }
      .col {
        width: 20%;
        .image-container {
          .image {
            img {
              object-fit: contain;
              @include media-breakpoint-up(xxl) {
                height: 150px;
              }
              @include media-breakpoint-between(md, xxl) {
                height: 100px;
              }
            }
          }
        }
      }
    }
    &.w200 {
      @include media-breakpoint-down(sm) {
        width: 600px;
      }
      .titles {
        width: 25%;
      }
      .col {
        width: 25%;
        .image-container {
          .image {
            img {
              object-fit: contain;
              @include media-breakpoint-up(xxl) {
                height: 160px;
              }
            }
          }
        }
      }
    }
    &.w300 {
      @include media-breakpoint-down(xm) {
        width: 420px;
      }
      .titles {
        width: 33.33%;
      }
      .col {
        width: 33.33%;
        .image-container {
          .image {
            img {
              object-fit: contain;
              @include media-breakpoint-up(xxl) {
                height: 160px;
              }
            }
          }
        }
      }
    }
    &.np {
      margin-top: 12px;
      .col {
        padding: 8px 0px 8px 8px;
        @include media-breakpoint-up(xl) {
          padding: 8px 0px 8px 16px;
        }
      }
    }
  }
}
</style>
